.myteam-container {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
}

.myteam-team-container,
.myteam-allocated-emps-container {
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.myteam-team-container-header,
.myteam-allocated-emps-container-header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.myteam-team-container-header span,
.myteam-allocated-emps-container-header span {
    font-size: 20px;
    font-weight: 550;
    color: #252525;
}

.myteam-team-container-header-input {
    position: relative;
    width: 35px;
    transition: .8s;
}

.myteam-team-container-header-input-span {
    position: relative;
    width: 250px;
    transition: .8s;
}

.myteam-team-container-header-input input,
.myteam-team-container-header-input-span input {
    width: 100%;
    height: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    border: 1px solid #646464;
    padding-left: 10px;
    font-size: 12px;
}

.myteam-team-container-header-icon {
    position: absolute;
    top: 3px;
    right: 5px;
    cursor: pointer;
    color: #646464;
}

.myteam-team-container-content,
.myteam-allocated-emps-container-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 0 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.mytime_team_container_card {
    width: 300px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 10px;
    transition: .6s;
    cursor: pointer;
}

.mytime_team_container_card:hover {
    scale: 1.05;
}

.team_card_container {
    display: flex;
}

.team_card_container_dummy_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team_card_container_dummy_img span {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
}

.team_card_container_details_name {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.team_card_container_details_name label {
    font-size: 14px;
    font-weight: bold;
    color: #252525;
    text-transform: capitalize;
}

.team_card_container_details_name span {
    font-size: 12px;
}