.project_codes_main_screen {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project_codes_card {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px #00000014;
    min-width: 600px;
}

.project_codes_card_header {
    background: #F1F2FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px 5px 0 0;
}

.project_codes_card_header_title span {
    font-size: 20px;
    font-weight: 550;
}


.project_codes_card_content {
    padding: 20px;
    max-height: 80vh;
}

.project_codes_card_upload_box {
    user-select: none;
}

.project_codes_card_upload_drag_drop {
    border: 2px dashed #6350FF;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.project_codes_card_upload_drag_drop span {
    font-size: 18px;
    font-weight: 550;
    color: #252525;
}


.project_codes_card_upload_file {
    display: flex;
    justify-content: center;
}

.project_codes_card_upload_file label {
    background: var(--bg-color);
    color: #ffffff;
    width: 140px;
    height: 35px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.project_codes_card_actions {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.project_codes_card_actions button {
    background: var(--bg-color);
    color: #ffffff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    width: 100px;
    height: 30px;
}


.project_code_extracted_data_table {
    width: 100%;
    height: 350px;
    overflow: auto;
}

.project_code_extracted_data_table span {
    font-size: 18px;
    font-weight: 500;
    color: #252525;
    display: block;
    padding: 10px;
    position: sticky;
    top: -10px;
    background: #ffffff;
}

.project_code_extracted_data_table table {
    width: 100%;
    border-collapse: collapse;
}

.project_code_extracted_data_table table thead tr,
.project_code_extracted_data_table table tbody tr {
    border-bottom: 1px solid #00000014;
}

.project_code_extracted_data_table table thead td,
.project_code_extracted_data_table table tbody td {
    padding: 10px;
    width: 100px;
}

.project_codes_no_data {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 550;
    color: #252525;
}

.project_codes_container_duplicate {
    /* background: red;
    border-bottom: 1px solid #ffffff; */
    color: red;
}