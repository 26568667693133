.holidays--container {
    width: 80%;
    margin: 10px auto;
}

.holidays--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.holidays-title span {
    font-size: 24px;
    color: #142952;
    font-weight: 600;
}


.holidays--actions button {
    height: 40px;
    color: #fff;
    background: var(--bg-color);
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 0 10px;
    outline: none;
}

.holidays--actions button:hover {
    scale: 1.05;
}

.holidays--actions button:active {
    scale: 0.9;
}

.holidays--content {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.holidays--filters {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    border-bottom: 2px solid #c7c7c7;
}

.holidays--filters--field {
    width: 30%;
}


.holidays--table {
    height: 55vh;
    overflow: auto;
    margin-top: 20px;
}


.holidays--table::-webkit-scrollbar {
    width: 5px;
}

.holidays--table::-webkit-scrollbar-thumb {
    background: #a1a1a1;
}

.holidays--table table {
    border-collapse: collapse;
    width: 100%;
}

.holidays--table table thead tr {
    position: sticky;
    top: 0;
    background: #ffffff;
}

.holidays--table table thead tr td {
    padding: 10px 15px;
    font-weight: 600;
}

.holidays--table table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.holidays--table table tbody tr:hover {
    background: #F4F4F4;
}

.holidays--table table tbody tr td {
    padding: 15px;
}


/* popup */

.import--holiday--container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: grid;
    place-items: center;
    z-index: 11;
}

.import--holiday--card {
    width: 600px;
    background: #fff;
    height: 80vh;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.6);
}

.import--holiday--header {
    background: #F1F2FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px 5px 0 0;
}

.import--holiday--header_title span {
    font-size: 20px;
    font-weight: 550;
}



.import--holiday--span-1 {
    user-select: none;
    width: 90%;
    margin: 30px auto;
}

.import--holiday--span-1--drag_drop {
    border: 2px dashed #6350FF;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.import--holiday--span-1--drag_drop span {
    font-size: 18px;
    font-weight: 550;
    color: #252525;
}


.import--holiday--span-1--upload_file {
    display: flex;
    justify-content: center;
}

.import--holiday--span-1--upload_file label {
    background: var(--bg-color);
    color: #ffffff;
    width: 140px;
    height: 35px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}



.import--holiday--table {
    width: 100%;
    margin-top: 10px;
    height: 60vh;
    padding: 10px;
    overflow: auto;
}

.import--holiday--table::-webkit-scrollbar {
    width: 10px;
}

.import--holiday--table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #b9b9b9;
}

.import--holiday--table table {
    border-collapse: collapse;
    width: 100%;
}

.import--holiday--table table thead tr {
    position: sticky;
    top: -10px;
    background: #ffffff;
}

.import--holiday--table table thead tr td {
    padding: 10px;
    font-weight: 600;
}

.import--holiday--table table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.import--holiday--table table tbody tr:hover {
    background: #F4F4F4;
}

.import--holiday--table table tbody tr td {
    padding: 15px;
}

.import--holiday--actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}


.import--holiday--actions button {
    height: 40px;
    color: #fff;
    background: var(--bg-color);
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    outline: none;
}

.import--holiday--actions button:hover {
    scale: 1.05;
}

.import--holiday--actions button:active {
    scale: 0.9;
}