.Monthly_Summary_container {
    position: relative;
    transition: 0.5s;
}

.Monthly_Summary_container_toggle {
    width: 30px;
    height: 30px;
    background: #858585;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: -30px;
}

.emp {
    margin-top: 20px;
}

.Monthly_Summary_filters {
    width: 100%;
    border-right: 1px solid #ddd8ff;
    overflow-y: auto;
    height: 89vh;
}

.Monthly_Summary_filters::-webkit-scrollbar {
    width: 2px;
}

.Monthly_Summary_filters_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.Monthly_Summary_filters_header_title {
    display: flex;
    align-items: center;
}

.Monthly_Summary_filters_header_title span {
    margin-left: 5px;
    color: #000000;
    font-size: 17px;
}

.Monthly_Summary_filters_header_clear {
    cursor: pointer;
    color: #6350ff;
}

.timesheet_reporting_filters_clear_all {
    transition: 0.4s;
    cursor: pointer;
}

.timesheet_reporting_filters_clear_all:hover {
    scale: 1.1;
}

.Monthly_Summary_filter_container {
    background: #ffffff;
    margin: 10px;
    border-radius: 8px;
    padding: 7px;
}

.Monthly_Summary_filter_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    color: #252525;
    font-weight: 500;
    /* border-bottom: 1px solid #C4C4C4; */
}

.Monthly_Summary_filter_container_content {
    margin-top: 20px;
    min-height: 100px;
    position: relative;
}

.Monthly_Summary_filter_container_content ul {
    padding: 10px;
    margin: 0;
    list-style: none;
}

.Monthly_Summary_filter_container_content ul li input {
    margin-top: 5px;
    margin-right: 10px;
}

.Monthly_Summary_filter_container_content_span_1 button,
.timesheet-reports-export-actions button {
    margin: 20px 10px;
    height: 35px;
    padding: 0 30px;
    border-radius: 4px;
    border: none;
    background: #6350ff;
    color: #ffffff;
    cursor: pointer;
}

.Monthly_Summary_filter_container_content_span {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.Monthly_Summary_filter_container_content_span input {
    width: 30%;
    padding-left: 5px;
}

.timesheet_timeline_btn_1 button,
.TimeSheet_Approval_filter_container_content_span_1 button {
    margin: 20px 10px;
    width: 70px;
    height: 35px;
    border-radius: 4px;
    border: none;
    background: #6350ff;
    color: #ffffff;
    cursor: pointer;
}

.timesheet_timeline_btn_1 {
    text-align: right;
}

.Monthly_Summary_main_screen {
    width: 100%;
    margin-left: 10px;
    height: 89vh;
}

.Monthly_Summary_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px;
}

.Monthly_Summary_container_header_title {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 20px;
}

.Monthly_Summary_container_header_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.Monthly_Summary_container_header_title label {
    background: #142952;
    color: #fff;
    width: 25px;
    height: 25px;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Monthly_Summary_container_header_export_btn {
    display: flex;
    gap: 20px;
}

.Monthly_Summary_container_header_export,
.Monthly_Summary_container_header_export_span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.Monthly_Summary_container_header_export button,
.Monthly_Summary_container_header_export_span button {
    padding: 0 15px;
    background: #6350ff;
    color: #ffffff;
    border: none;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
}

.Monthly_Summary_container_table {
    height: 72vh;
    overflow-y: auto;
    background: #ffffff;
    width: 99%;
    /* padding: 10px; */
    /* border-radius: 8px; */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.Monthly_Summary_container_table::-webkit-scrollbar {
    width: 1px;
}

.Monthly_Summary_container_table table {
    border-collapse: collapse;
    width: 100%;
}

.Monthly_Summary_container_table table thead tr {
    position: sticky;
    top: -3px;
    background: #ffffff;
    border-bottom: 1px solid #d9d5ec;
    color: #142952;
}

.Monthly_Summary_container_table table thead tr th {
    padding: 5px;
    /* padding: 10px; */
    font-weight: 550;
    font-size: 14px;
    border: 1px solid #d9d5ec;
}

.Monthly_Summary_container_table table thead tr th:first-child {
    font-weight: 550;
    text-align: center;
}

.Monthly_Summary_container_table table thead tr th span {
    display: flex;
    gap: 5px;
}

.Monthly_Summary_container_table table tbody {
    color: #142952;
}

.Monthly_Summary_container_table table tbody tr td:first-child {
    text-transform: capitalize;
}

.Monthly_Summary_container_table table tbody tr {
    border-bottom: 1px solid #d9d5ec;
    cursor: pointer;
}

.Monthly_Summary_container_table table tbody tr:hover {
    /* background: #DDE6ED; */
    background: #f4f4f4;
}

.Monthly_Summary_container_table table tbody tr td {
    /* padding: 15px; */
    padding: 5px;
    border: 1px solid #d9d5ec;
}