.alert-with-method-container {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    user-select: none;
}

.alert_with_method {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px;
}

.alert_with_method_message {
    display: flex;
    align-items: center;
}

.alert_with_method_message span {
    margin-left: 5px;
}

.alert_with_method_btn button {
    width: 50px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    background: #ffffff;
    color: #000000;
    border: none;
}

.alert_with_method_btn button:first-child {
    margin-right: 10px;
}