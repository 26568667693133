.header_main_page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 11vh;
    /* padding: 20px; */
}

.header_main_bg {
    background: #ffffff;
    border-bottom: 0.5px solid #DDD8FF;
}

.header_logo img {
    width: 250px;
    cursor: pointer;
}

/* 
.header_logo span {
    margin-right: 2;
    letter-spacing: 2px;
    text-decoration: none;
    font-weight: 700;
    font-size: 28px;
    cursor: pointer;
    user-select: none;
} */

.header_profile_details {
    display: flex;
    align-items: center;
    cursor: 'pointer';
    margin-right: 20px;
    height: 10vh;
}

.dummy_image_profile {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.dummy_image_profile span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    cursor: pointer;
    font-weight: 600;
}

.header_profile_image {
    width: 40px;
    height: 40px;
    position: relative;
    margin-left: 20px;
}

.header_profile_image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
}

.header_profile_image_popup {
    position: absolute;
    top: 50px;
    left: -155px;
    background: #ffffff;
    z-index: 999;
    width: 200px;
    border-radius: 5px;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
}

.header_profile_image_popup::after {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    /* border-bottom: 10px solid #11568f; */
    border-bottom: 10px solid #fff;
}

.header_profile_image_popup ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.header_profile_image_popup ul li {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    color: #444444;
}

.header_profile_image_popup ul li:hover {
    background: #EEEFF4;
    font-weight: bold;
}

.header_logout_btn {
    border: 2px solid #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    transition: 1s;
    background: #d85c5c;
    cursor: pointer;
}

.header_logout_btn:hover {
    transform: scale(1.1);
    transition: 1s;
}