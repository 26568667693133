.employee_approval_container {
    padding: 10px 20px;
}

.employee_approval_header {
    margin-top: 5px;
    margin-left: 5px;
}

.employee_approval_header span {
    font-size: 22px;
    font-weight: 500;
    color: #142952;
}

.employee_approval_table_container {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin-top: 20px;
    padding: 10px;
}

.employee_approval_table {
    height: 60vh;
    overflow-y: auto;
}


.employee_approval_table table {
    border-collapse: collapse;
    width: 100%;
}

.employee_approval_table table thead tr {
    position: sticky;
    top: -10px;
    background: #ffffff;
}

.employee_approval_table table thead tr td {
    padding: 10px;
}

.employee_approval_table table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.employee_approval_table table tbody tr:hover {
    background: #F4F4F4;
}

.employee_approval_table table tbody tr td {
    padding: 10px;
}

.employee_approval_table table tbody tr td:last-child {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}