.project_allocation_main_screen {
    display: flex;
}

.project_allocation_filters_container {
    position: relative;
    transition: .5s;
}

.project_allocation_filters_container_toggle {
    width: 30px;
    height: 30px;
    background: #858585;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: -30px;
}

.project_allocation_filters {
    width: 100%;
    border-right: 1px solid #DDD8FF;
    height: 89vh;
    overflow-y: auto;
}

.project_allocation_filters::-webkit-scrollbar {
    width: 2px;
}

.project_allocation_filters_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.project_allocation_filters_header_title {
    display: flex;
    align-items: center;
}

.project_allocation_filters_header_title span {
    margin-left: 5px;
    color: #000000;
    font-size: 17px;
}

.project_allocation_filters_header_clear {
    cursor: pointer;
    color: var(--bg-color);
}

.project_allocation_filters_clear_all {
    transition: .4s;
    cursor: pointer;
}

.project_allocation_filters_clear_all:hover {
    scale: 1.1;
}

.project_allocation_filter_container {
    background: #ffffff;
    margin: 10px;
    border-radius: 8px;
    padding: 7px;
}

.project_allocation_filter_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    color: #252525;
    font-weight: 500;
    /* border-bottom: 1px solid #C4C4C4; */
}


.project_allocation_filter_container_content {
    margin-top: 20px;
    min-height: 100px;
}

.project_allocation_filter_container_content ul {
    padding: 10px;
    margin: 0;
    list-style: none;
}

.project_allocation_filter_container_content ul li input {
    margin-top: 5px;
    margin-right: 10px;
}

.project_allocation_filter_container_content_span_1 button {
    margin: 20px 10px;
    width: 70px;
    height: 35px;
    border-radius: 4px;
    border: none;
    background: var(--bg-color);
    color: #ffffff;
    cursor: pointer;
}

.project_allocation_filter_container_content_span {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.project_allocation_filter_container_content_span input {
    width: 30%;
    padding-left: 5px;
}

.project_allocation_container {
    max-width: 100%;
    min-width: 80%;
    padding: 0 20px;
}

.project_allocation_container_search_btn {
    position: absolute;
    top: 5px;
    right: 10px;
}

.project_allocation_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
}

.project_allocation_container_header_title {
    display: flex;
    align-items: center;
    gap: 5px;
}

.project_allocation_container_header_title span {
    font-size: 22px;
    color: #142952;
    font-weight: 500;
}

.project_allocation_container_header_title label {
    background: #142952;
    color: #fff;
    width: 25px;
    height: 25px;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project_allocation_container_header_navigations {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.project_allocation_container_header_navigations button {
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 10px;
    background: var(--bg-color);
    border: none;
    color: #ffffff;
}

.project_allocation_container_table {
    width: 100%;
    background: #ffffff;
    margin-top: 15px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 65vh;
    padding: 10px;
    overflow: auto;
}

.project_allocation_container_table::-webkit-scrollbar {
    width: 0;
}

.project_allocation_container_table table {
    border-collapse: collapse;
    width: 100%;
}

.project_allocation_container_table table thead tr {
    position: sticky;
    top: -10px;
    background: #ffffff;
}

.project_allocation_container_table table thead tr td {
    padding: 10px;
}

.project_allocation_container_table table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.project_allocation_container_table table tbody tr:hover {
    background: #F4F4F4;
}

.project_allocation_container_table table tbody tr td {
    padding: 15px;
}