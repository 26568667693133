.dashboard-loader--container {
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
}

.dashboard--loader--image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.dashboard--loader--image--logo {
    width: 400px;
    margin-right: -50px;
}

.dashboard--loader--image--loader {
    width: 130px;
    margin-top: -100px;
}