.manage_client {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.manage_client_card {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px #00000014;
    border-radius: 7px;
    width: 400px;
}

.manage_client_header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F1F2FF;
    border-bottom: 1px solid #D9D5EC;
    border-radius: 5px 5px 0 0;
}

.manage_client_header span {
    font-size: 24px;
    font-weight: 550;
    color: #252525;
}

.manage_client_content {
    padding: 10px;
}

.manage_client_content_fields {
    margin-top: 15px;
}

.manage_client_content_fields label {
    width: 100%;
    color: #142952;
    font-size: 12px;
}


.manage_client_content_fields input {
    width: 100%;
    height: 35px;
    outline: 1px solid #0044CC;
    border: none;
    border-radius: 4px;
    padding-left: 10px;
}

.manage_client_content_fields input::placeholder {
    color: #444444;
}

.manage_client_action {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}


.manage_client_action button {
    width: 100px;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    color: #ffffff;
    background: var(--bg-color);
    border: none;
}

.error_border {
    outline: 1px solid red !important;
}