.filters {
  margin-bottom: 16px;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.filter-button {
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.filter-button.active {
  background: #38a3a5;
  color: white;
}

.filter-button.inactive {
  background: #ddd;
  color: black;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.action-button {
  background: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.no-columns-message {
  text-align: center;
  margin: 20px;
  font-size: 18px;
  color: #555;
}

.gridjs .gridjs-search {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.gridjs .gridjs-search input {
  width: 300px;
  padding: 5px 10px;
  font-size: 14px;
}
