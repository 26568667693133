.ProjectCode_main_screen {
  display: flex;
}

.ProjectCode_filters_container {
  position: relative;
  transition: 0.5s;
}

.ProjectCode_filters_containertoggle {
  width: 30px;
  height: 30px;
  background: #858585;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: -30px;
}

.ProjectCode_filters {
  width: 100%;
  border-right: 1px solid #ddd8ff;
  height: 89vh;
  overflow-y: auto;
}

.ProjectCode_filters::-webkit-scrollbar {
  width: 2px;
}

.ProjectCode_filters_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.ProjectCode_filters_header_title {
  display: flex;
  align-items: center;
}

.ProjectCode_filters_header_title span {
  margin-left: 5px;
  color: #000000;
  font-size: 17px;
}

.ProjectCode_filters_header_clear {
  cursor: pointer;
  color: var(--bg-color);
}

.ProjectCode_filters_header_clear span {
  cursor: pointer;
}

.ProjectCode_filter_container {
  background: #ffffff;
  margin: 10px;
  border-radius: 8px;
  padding: 7px;
}

.ProjectCode_filter_container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  color: #252525;
  font-weight: 500;
  /* border-bottom: 1px solid #C4C4C4; */
}

.ProjectCode_filter_container_content {
  margin-top: 20px;
  /* min-height: 100px; */
}

.ProjectCode_filter_container_content ul {
  padding: 10px;
  margin: 0;
  list-style: none;
}

.ProjectCode_filter_container_content ul li input {
  margin-top: 5px;
  margin-right: 10px;
}

.ProjectCode_filter_container_content_span_1 button {
  margin: 20px 10px;
  width: 70px;
  height: 35px;
  border-radius: 4px;
  border: none;
  background: var(--bg-color);
  color: #ffffff;
  cursor: pointer;
}

.ProjectCode_filter_container_content_span {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.ProjectCode_filter_container_content_span input {
  width: 30%;
  padding-left: 5px;
}

.ProjectCode_container {
  width: 100%;
  padding: 15px;
}

.ProjectCode_container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.ProjectCode_container_header_title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ProjectCode_container_header_title span {
  font-size: 22px;
  color: #142952;
  /* font-weight: 500; */
  font-weight: 500;
}

.ProjectCode_container_header_title label {
  background: #142952;
  color: #fff;
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectCode_container_header_navigations button {
  width: 100px;
  height: 30px;
  background: var(--bg-color);
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

.ProjectCode_content_table {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.ProjectCode_container_table {
  width: 100%;
  margin-top: 10px;
  height: 100vh;
  padding: 10px;
  overflow: auto;
}

.ProjectCode_container_table::-webkit-scrollbar {
  width: 0px;
}

.ProjectCode_container_table table {
  border-collapse: collapse;
  width: 100%;
}

.ProjectCode_container_table table thead tr {
  position: sticky;
  top: -10px;
  background: #ffffff;
}

.employees_container_table table thead tr td {
  padding: 10px;
}

.ProjectCode_container_table table tbody tr {
  border-bottom: 1px solid #d9d5ec;
  cursor: pointer;
}

.ProjectCode_container_table table tbody tr:hover {
  background: #f4f4f4;
}

.ProjectCode_container_table table tbody tr td {
  padding: 15px;
}
