.edit_project_code_container {
  width: 95%;
  padding: 10px;
}

.edit_project_code_container_header {
  user-select: none;
  padding: 10px;
}

.edit_project_code_container_header_title {
  font-size: 22px;
  font-weight: 600;
  color: #142952;
  margin-bottom: 10px;
}

.edit_project_code {
  width: 800px;
  height: 400px;
  top: 178px;
  left: 156px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px #00000014;
  padding: 20px;
  background: #ffffff;
}

.edit_project_code_flex_div {
  display: flex;
  justify-content: center;
}

.edit_project_code_header {
  width: 100%;
  height: 246px;
  top: 208px;
  left: 176px;
  border-radius: 4px;
}

.edit_project_code_header_two {
  width: 654px;
  height: 265px;
  top: 208px;
  left: 707px;
  border-radius: 4px;
}

.edit_project_code_input_box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.edit_project_code_container_span {
  width: 47%;
}

.edit_project_code_btn button {
  width: 90px;
  height: 35px;
  border-radius: 4px;
  background: var(--bg-color);
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.edit_project_code_container_span {
  margin-right: 25px;
}

.edit_project_code_container_navigations {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  padding-right: 15px;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 40px !important;
}
