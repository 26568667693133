.update-employee-container {
    background: #fff;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    /* padding: 20px; */
    box-shadow: 12px 15px 15px -3px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding-bottom: 1px;
}

.update-employee-title {
    background: #fff;
    padding: 20px;
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c7c7c7;
}

.update-employee-title span {
    font-size: 20px;
    color: #252525;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.update-employee-title button {
    height: 35px;
    color: #fff;
    background: var(--bg-color);
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 0 10px;
    outline: none;
}

.update-employee-title button:hover {
    scale: 1.1;
}

.update-employee-title button:active {
    scale: .9;
}

.update-employee-card {
    margin: 60px 20px;
    /* margin-top: 60px; */
    border-radius: 4px;
    padding: 10px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.update-employee-card-title {
    position: absolute;
    top: -40px;
    left: 0;
    padding: 5px 30px;
    height: 40px;
    border-radius: 3px 3px 0 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.update-employee-card-title span {
    font-weight: 600;
    font-size: 16px;
}

.update-employee-span {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
}

.update-employee-field {
    margin: 15px 0;
    width: 47%;
    display: flex;
    align-items: center;
}

.update-employe-domain-add {
    width: 40px;
    height: 40px;
    background: #c7c7c7;
    display: grid;
    place-items: center;
    user-select: none;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 20px;
}

.update-employee-table {
    width: 47%;
    margin-top: 20px;
}

.update-employee-table table {
    width: 100%;
    border-collapse: collapse;
}

.update-employee-table table thead tr th {
    padding: 10px;
    background: #d9d9d9;
}

.update-employee-table table tbody tr td {
    padding: 5px 10px;
}

.update-employee-table table thead tr th:last-child,
.update-employee-table table tbody tr td:last-child {
    text-align: center;
    cursor: pointer;
}