.footer_version {
    position: fixed;
    bottom: 10px;
    text-align: center;
    width: 100%;
    /* right: 20px; */
    /* color: #757575; */
    color: #1c73ba;
    font-weight: 600;
    font-size: 14px;

}

.footer_version:hover {
    transform: scale(1.1);
    /* Scale up on hover */
}