.i_loading {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 222;
    user-select: none;
}

.i_center_loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
}

.i_center_loader img {
    width: 400px;
    animation: blowUp 3s infinite linear;
}


@keyframes blowUp {
    0% {
        transform: rotate(360deg);
    }
}

.i_center_loader h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 32px;
    color: #fff;
}




/* new */


.i-employee-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #03343d;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 222;
    user-select: none;
}

.i-employee-loading-content {
    text-align: center;
}

.i-employee-loading-content h2 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 600;
}

.i-employee-loading-container {
    border: 1px solid #ffff;
    width: 250px;
    height: 30px;
    border-radius: 15px;
    position: relative;
}

.i-employee-loading-container span {
    display: block;
    background: #fff;
    width: 50px;
    height: 25px;
    border-radius: 15px;
    position: absolute;
    top: 2px;
    /* left: 195px; */
    animation: foraward linear infinite 4s;
}

@keyframes foraward {
    0% {
        left: 2px;
        width: 50px;
    }

    25% {
        width: 70px;
    }

    50% {
        left: 195px;
        width: 50px;
    }

    75% {
        width: 70px;
    }

    100% {
        left: 2px;
        width: 50px;
    }
}


/* New  */





.iemployee-loading-container {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 222;
    user-select: none;
}


.iemployee-loading-spans {
    display: flex;
    gap: 8px;
}


.iemployee-loading-spans1,
.iemployee-loading-spans2,
.iemployee-loading-spans3,
.iemployee-loading-spans4,
.iemployee-loading-spans5 {
    width: 8px;
    height: 30px;
    border-radius: 2px;
    background: #ffffff;
    animation: scaleUp 3s infinite linear;
}

.iemployee-loading-spans1 {
    animation-delay: 0.4s;
}

.iemployee-loading-spans2 {
    animation-delay: 0.6s;
}

.iemployee-loading-spans3 {
    animation-delay: 0.8s;
}

.iemployee-loading-spans4 {
    animation-delay: 1.2s;
}

.iemployee-loading-spans5 {
    animation-delay: 1.4s;
}


@keyframes scaleUp {
    0% {
        transform: scaleY(1);
    }

    25% {
        transform: scaleY(2);
    }

    50% {
        transform: scaleY(1);
    }

    75% {
        transform: scaleY(2);
    }

    100% {
        transform: scaleY(1);
    }
}