.login_container {
    width: 100%;
    height: 100vh;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.login_image_container {
    width: 400px;
    text-align: center;
}

.login_image_container img {
    width: 100%;
}

.login_form {
    width: 350px;
}

.login_form_card {
    text-align: center;
    width: 100%;
    background: #ffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 350px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px 0;
}


.login_form_card_header span,
.login_form_card_1_header span {
    display: block;
    color: #757575;
    font-weight: 600;
    font-size: 20px;
}

.login_form_card_image_container {
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.login_form_card_image_container .login_form_card_image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.login_form_azure_image_container {
    overflow: hidden;
    border-radius: 50%;
}

.login_form_azure_image_container img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.6s;
}

.login_form_card_image_container img:hover {
    scale: 1.2;
}

.login_form_card_image span {
    font-size: 24px;
    font-weight: 600;
    color: #000;
}

.login_form_card_btn button {
    width: 50%;
    border-radius: 5px;
    height: 35px;
    border: none;
    cursor: pointer;
}

.login_form_card_btn_continue {
    background: #1c73ba;
    color: #ffff;
}

.login_form_card_btn_redirect {
    background: red;
    color: #ffff;
}

.login_form_other_account {
    color: #1c73ba;
}

.login_form_other_account span {
    cursor: pointer;
}

.login_form_card_1 {
    width: 100%;
    height: 350px;
    background: #ffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.login_form_card_1_header {
    padding: 10px;
}

.login_form_card_1_content {
    padding: 0 30px;
}

.login_form_card_1_content_span {
    margin-top: 20px;
}

.login_form_card_1_content_span label {
    font-size: 14px;
    font-weight: 500;
    color: #252525;
}

.login_form_card_1_content_span input {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #454545;
    outline: none;
    padding-left: 10px;
}

.login_form_card_1_content_span input:focus {
    border: 1px solid gray;
}

.login_form_card_1_forgot {
    padding: 10px 30px;
}

.login_form_card_1_forgot span {
    color: #1c73ba;
    font-weight: 550;
    font-size: 12px;
    cursor: pointer;
}

.login_form_card_1_btn {
    display: flex;
    justify-content: center;
}

.login_form_card_1_btn button {
    width: 80%;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #1c73ba;
    cursor: pointer;
    background: #1c73ba;
    color: #ffff;
}

.login_form_card_1_go_back {
    padding: 10px;
    text-align: center;
}

.login_form_card_1_go_back span {
    color: #1c73ba;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.login_genzeon-logo {
    position: absolute;
    top: -80px;
    left: 20px;
}

.login_genzeon-logo img {
    width: 300px;
    /* filter: drop-shadow(0 0 10px rgb(28, 115, 186, 0.6)); */
    cursor: pointer;
}