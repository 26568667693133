.employee_reports_container {
    width: 95%;
    margin-left: 20px;
}

.employee_reports_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px;
}

.employee_reports_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.employee_reports_navigations button {
    width: 90px;
    height: 35px;
    border-radius: 4px;
    background: var(--bg-color);
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.employee_reports_content {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.employee_reports_table {
    height: 62vh;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
}

.employee_reports_table::-webkit-scrollbar {
    width: 1px;
}

.employee_reports_table table {
    border-collapse: collapse;
    width: 100%;
}

.employee_reports_table table thead tr {
    position: sticky;
    top: -10px;
    background: #ffffff;
    border-bottom: 1px solid #D9D5EC;
    color: #142952;
}

.employee_reports_table table thead tr td {
    padding: 10px;
    font-weight: 550;
}

.employee_reports_table table tbody {
    color: #142952;
}

.employee_reports_table table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.employee_reports_table table tbody tr:last-child {
    border: none;
}

.employee_reports_table table tbody tr:hover {
    background: #F4F4F4;
}

.employee_reports_table table tbody tr td {
    padding: 15px;
}