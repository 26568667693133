/* Making footer down in every screen */

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
}

.app::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.app::-webkit-scrollbar-thumb {
    background: #f4f4f4;
}

.protectedRoutes {
    flex: 1;
}