.project_code_generation_display_page {
    display: flex;
    background: #F1F2FF;
}

.project_code_generation_screens {
    width: 100%;
    height: 89vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    /* padding: 2% 1% 2% 4%; */
}

.project_code_generation_screens::-webkit-scrollbar {
    width: 10px;
}

.project_code_generation_screens::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 5px;
}