.error_page_container {
    background: #fff;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error_page_card {
    width: 800px;
    display: flex;
    background: #fff;
    box-shadow: 0 0 10px #000;
    border-radius: 5px;
}

.error_page_image_container {
    width: 80%;
}

.error_page_image_container img {
    width: 100%;
    border-radius: 5px;
}

.error_page_details {
    position: relative;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error_img_container {
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    text-align: center;
}

.error_img_container img {
    width: 100%;
}

.error_details {
    color: #fff;
    z-index: 99;
    text-align: center;
}

.error_details span {
    color: #fff;
    display: block;
    cursor: pointer;
}