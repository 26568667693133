.timesheet_container {
    width: 95%;
    margin-bottom: 20px;
    margin-left: 20px;
}

.timesheet_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px;
}

.timesheet_container_header_navigations label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
}

.timesheet_container_header_navigate_btns {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 30%;
}

.timesheet_container_header_navigate_btns button {
    width: 70px;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    background: var(--bg-color);
    color: #FFFFFF;
    font-size: 14px;
    /* width: 100px; */
    height: 30px;
    margin-right: 10px;
}

.timesheet_container_header_navigate_show_week {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}

.timesheet_container_header_navigate_show_week b {
    font-size: 16px;
}

.timesheet_container_header_week_btn {
    background: var(--bg-color);
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    user-select: none;
}

.timesheet_content {
    background: #ffffff;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    margin-top: 6px;
}

.timesheet_content::-webkit-scrollbar {
    width: 1px;
}

.timesheet_content_data {
    background: #ffffff;
    width: 100%;
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #D9D5EC;
}

.timesheet_content_span_1 {
    width: 50%;
}

.timesheet_content_span_1 label {
    color: #252525;
    font-weight: 600;
}

.timesheet_content_span_1 label::after {
    content: ":";
}

.timesheet_content_span_1 span {
    margin-left: 10px;
}

.timesheet_content table {
    border-collapse: collapse;
    width: 100%;
}

.timesheet_content table thead tr {
    position: sticky;
    top: -2px;
    color: #142952;
    border-bottom: 1px solid #D9D5EC;
    background: #ffffff;
}

.timesheet_content table thead tr td {
    /* padding: 10px; */
    padding: 5px;
    font-weight: 520;
    font-size: 15px;
}

.timesheet_content table thead tr td:first-child {
    font-weight: 550;
}


.timesheet_content table tbody tr td:first-child {
    font-weight: 505;
}

.timesheet_content table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.timesheet_content table tbody tr:last-child {
    border: none;
    background: #F4F4F4;
}

.timesheet_content table tbody tr:hover {
    background: #F4F4F4;
}

.timesheet_content table tbody tr td {
    /* padding: 15px; */
    padding: 5px;
    color: #142952;
    font-size: 14px;
}


.timesheet_content table tbody tr td input {
    width: 60px;
    height: 30px;
    text-align: center;
    border: 1px solid #D9D5EC;
    outline: none;
    border-radius: 5px;
    background: #f1f2ff;
}


/* Comment */


.timesheet_comment_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.timsheet_comment_card {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    width: 350px;
}

.timesheet_comment_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background: #F1F2FF;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #D9D5EC;
}

.timesheet_comment_header span {
    font-size: 20px;
    font-weight: 500;
}

.timesheet_comment_content {
    padding: 10px;
}

.timesheet_comment_content textarea {
    width: 100%;
    height: 40px;
    outline: 1px solid #D9D5EC;
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.timesheet_comment_content textarea:focus {
    outline: 1px solid #D9D5EC;
    border: none;
}

.timesheet_comment_content textarea::-webkit-scrollbar {
    width: 0px;
}

.timesheet_comment_count {
    display: flex;
    justify-content: flex-end;
}

.timesheet_comment_count span {
    font-size: 12px;
}

.timesheet_comment_actions {
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.timesheet_comment_actions button {
    width: 90px;
    height: 30px;
    color: #ffffff;
    background: var(--bg-color);
    border-radius: 5px;
    border: none;
    cursor: pointer;
}