.employee_profile_download_btn {
    background: var(--bg-color);
    color: #ffffff;
    border: none;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 20px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.employee_profile_download_btn img {
    margin-right: 5px;
}