.iemployee-dashboard-container {
    display: flex;
}

.iemployee-about-us-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    position: relative;
}

.iemployee-about-us-container-span img {
    width: 230px;
    position: absolute;
    top: 10px;
    left: 10px;
    mix-blend-mode: multiply;
}

.iemployee-about-us-container-span {
    margin-top: 60px;
}

.iemployee-about-us-container-span span {
    display: block;
    color: #025b99;
    font-weight: 600;
    font-size: 38px;
    margin-left: 60px;
}


.iemployee-modules-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
}

.iemployee-module-card-content {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}


.iemployee-module-card {
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    transition: .6s;
}

.iemployee-module-card:hover {
    scale: 1.05;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: rotateZ(1deg);
}

.iemployee-module-card span {
    color: #252525;
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
}

.iemployee-module-card-img-container img {
    width: 80px;
}

.iemployee-teams-button {
    position: absolute;  /* Position it relative to the whole page */
    bottom: 10px;           /* Adjust the top distance if needed */
    right: 10px;          /* Place it at the very left edge of the page */
    padding: 2px 5px;    /* Small padding for a compact size */
    font-size: 10px;     /* Small text size */
    /* background-color: transparent; */
    /* color: white; */
    /* border: rgb(255, 255, 255); */
    border-radius: 40%;
    cursor: pointer;
    z-index: 1000;       /* Ensure it's above other content */
}

.iemployee-teams-button:hover {
    background-color: #d3ebfd;
}



.iemployee-teams-svg {
    transition: transform 0.8s ease; /* Smooth rotation transition */
}

.iemployee-teams-button:hover .iemployee-teams-svg {
    transform: rotate(360deg); /* Flip the SVG on hover */
}

.iemployee-teams-button:hover {
    background-color: #d3ebfd;
    border: none;
}



.iemployee-teams-button {
    position: absolute;  /* Position it relative to the whole page */
    width: 70px;
    height: 70px;
    bottom: 10px;           /* Adjust the top distance if needed */
    right: 10px;            /* Place it at the bottom right of the page */
    padding: 2px 5px;       /* Small padding for a compact size */
    font-size: 10px;        /* Small text size */
    border-radius: 60%;     /* Rounded corners */
    cursor: pointer;
    z-index: 1000;          /* Ensure it's above other content */
    overflow: hidden;       /* Crop overflow if image doesn't fit */
    display: flex;          /* Center image if smaller than div */
    align-items: center;
    justify-content: center;
    border: none;
}

.iemployee-teams-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Ensures the image covers the div without distortion */
    border-radius: 50%; /* Make the image circular to match div's border-radius */
}




.iemployee-teams-svg {
    transition: transform 0.20s ease; /* Smooth rotation transition */
}

.iemployee-teams-button:hover .iemployee-teams-svg {
    transform: rotate(360deg); /* Flip the SVG on hover */
}

.iemployee-teams-button:hover img {
    transform: scale(1.1);  /* Zoom in slightly on hover */
}

/* 
.iemployee-globe {
    position: fixed;
    bottom: -90px;
    left: -90px;
}

.iemployee-globe img {
    width: 300px;
    animation: rotate linear infinite 5s;
}


@keyframes rotate {
    0% {
        transform: rotate(-360deg);
        scale: 0.7;
    }

    50% {
        scale: 1.1;
    }

    100% {
        scale: 0.7;
    }
} */