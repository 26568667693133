.NoData {
    width: 100%;
    background: #fff;
}

.empty_table_img_container {
    text-align: center;
}

.empty_table_img_container img {
    width: 250px;
    /* width: 350px; */
    transition: .4s;
    cursor: pointer;
    margin-top: 20px;
}


.empty_table_img_container img:hover {
    transform: scale(1.1);
    transition: .4s;
}

.empty_table_img_container h3 {
    font-weight: 700;
    font-size: 20px;
}