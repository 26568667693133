.pagination_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    border-top: 1px solid #D9D5EC;
}

.pagination_select_row {
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 10px #00000029;
}

.pagination_select_row select {
    width: 50px;
    text-align: center;
    border: none;
    outline: none;
}

.pagination_bar {
    display: flex;
    gap: 5px;
    user-select: none;
}

.pagination_bar span {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #F1F1F1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.employee-pagination-active {
    background: #EEEFF4;
    font-weight: bold;
}