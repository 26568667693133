.profile_container {
    padding: 20px;
}

.profile_container_header {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile_container_header span {
    font-size: 22px;
    color: #142952;
    font-weight: 500;
}

.profile_container_content {
    background: #ffffff;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.profile_container_content_span {
    display: flex;
}

.profile_container_image_container {
    padding: 20px;
    width: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.profile_container_image_container_span {
    width: 150px;
    min-height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;

}

.profile_container_image_container_span>span {
    font-size: 38px;
    font-weight: 400;
}

.profile_container_image_container_span img {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.profile_container_image_container_upload {
    /* display: flex; */
    /* justify-content: center; */
    margin-top: 20px;
}

.profile_container_image_container_upload button {
    width: 150px;
    cursor: pointer;
    margin-top: 10px;
}

.profile_options_container {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.profile_options_container_add {
    width: 30px;
    height: 30px;
    background: #c7c7c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.profile_options_container_span {
    position: absolute;
    background: #f2eeee;
    border-radius: 5px;
    width: 130px;
}

.profile_options_container_span>span {
    padding: 10px;
    display: block;
    cursor: pointer;
}

.profile_options_container_span>span:hover {
    background: #ece8e8;
}

.profile_container_user_data {
    margin-left: 20px;
    margin-top: 20px;
    width: 70%;
}

.profile_container_user_data_name {
    padding: 10px;
    border-bottom: 1px solid #B9B9B9;
}

.profile_container_user_data_name label {
    font-size: 20px;
    font-weight: 550;
    color: #464455;
}

.profile_container_user_data_name span {
    color: #464455;
    font-size: 14px;
}

.profile_container_user_data_span_1 {
    padding: 15px;
    display: flex;
}

.profile_container_user_data_span_content {
    width: 30%;
}

.profile_container_user_data_span_content label {
    width: 100%;
    font-size: 12px;
    color: #6C6C6C;
}

.profile_container_user_data_span_content span {
    margin-top: 5px;
    font-size: 16px;
    color: #252525;
}

.profile_container_navigations {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.profile_image_actions_btn_span button,
.profile_container_navigations button,
.profile_container_header button {
    cursor: pointer;
    height: 35px;
    background: var(--bg-color);
    color: #ffffff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 0 20px;
}

.employee-resume-btn,
.employee-resume-btn-1 {
    height: 35px;
    background: var(--bg-color);
    color: #ffffff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.employee_resume_container {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-top: 20px;
}

.employee_resume_container_header {
    padding: 10px;
    margin-top: 30px;
}

.employee_resume_container_header span {
    color: #464455;
    font-weight: 540;
    font-size: 17px;
}

.employee_resume_container_drag_drop {
    max-width: 60%;
    width: 100%;
    margin: 20px auto;
    height: 200px;
    border: 2px dashed #6350FF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.employee_resume_container_drag_drop p {
    color: #464455;
    font-size: 20px;
    font-weight: 500;
}

.employee_resume_container_or {
    text-align: center;
}

.employee_resume_container_upload {
    text-align: center;
    margin-top: 10px;
}

.employee_resume_container_upload button {
    margin-bottom: 20px;
    background: var(--bg-color);
    border: none;
    border-radius: 6px;
    color: #ffffff;
    width: 130px;
    height: 35px;
    cursor: pointer;
}

.employee_resume_container_upload span {
    display: block;
    margin-top: 5px;
    color: #6C6C6C;
    font-size: 12px;
}

.employee_resume_container_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
}

.employee_resume_container_actions input {
    background: var(--bg-color);
    border: none;
    border-radius: 6px;
    color: #ffffff;
    width: 130px;
    height: 35px;
    cursor: pointer;
}

.profile_image_upload:hover {
    opacity: 0.4;
}

.employee-resume-btn-1 {
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.employee-resume-btn-1:hover {
    color: #ffff;
}

.display_resume_btns {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.display_resume_box {
    padding: 10px;
    display: flex;
    justify-content: center;
}

#employee-resume {
    width: 600px;
    height: 400px;
}