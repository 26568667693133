.navigation_bar_main_page_projectAllocation {
    width: 8%;
    background: #ffffff;
    border-right: 0.5px solid #DDD8FF;
    height: 89vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 10px;
}

.navigation_bar_main_page_projectAllocation::-webkit-scrollbar {
    width: 0;
}

.navigations_projectAllocation {
    flex: 1;
}

.navigations_projectAllocation a {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* color: #fff; */
    color: #3e5363;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 10px;
    border-radius: 5px;
    font-weight: 500;
}

.navigations_projectAllocation a:hover {
    background: #EEEFF4;
}

.navigations_projectAllocation a span {
    font-size: 12px;
}

.navigations_projectAllocation .active {
    background: #EEEFF4;
    font-weight: 600;
}

.navigation_footer_active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 3%;
}

.navigation_footer_active img {
    cursor: pointer;
}

/* Footer Enabling */

.footer_enable {
    position: fixed;
    bottom: 0px;
    left: 6.5%;
    z-index: 18;
    background: #1c73ba;
    width: 400px;
    height: 300px;
    color: #fff;
    z-index: 18;
    border-radius: 0 5px 0 0;
    transition: 1s;
}


.footer_disable {
    position: fixed;
    bottom: 0px;
    left: -100%;
    z-index: 18;
    width: 50px;
    height: 50px;
    background: #1c73ba;
    color: #fff;
    transition: 2s;
    overflow: hidden;
}

/* New one */