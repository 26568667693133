.alert_close {
    position: fixed;
    top: 10px;
    left: 35%;
    width: 350px;
    display: flex;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    user-select: none;
}

.alert_close_message {
    display: flex;
    align-items: center;
}

.alert_close_message span {
    margin-left: 5px;
}