.employee_audit_container {
    width: 95%;
    margin-left: 20px;
}

.employee_audit_header {
    user-select: none;
    padding: 10px;
}

.employee_audit_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.employee_audit_content {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 10px;
}

.employee_audit_table_container {
    height: 60vh;
    overflow-y: auto;
    width: 100%;
}

.employee_audit_table_container::-webkit-scrollbar {
    width: 1px;
}

.employee_audit_table_container table {
    border-collapse: collapse;
    width: 100%;
}

.employee_audit_table_container table thead tr {
    position: sticky;
    top: -2px;
    background: #ffffff;
    border-bottom: 1px solid #D9D5EC;
    color: #142952;
}

.employee_audit_table_container table thead tr td {
    padding: 5px;
    font-weight: 550;
}

.employee_audit_table_container table tbody {
    color: #142952;
}

.employee_audit_table_container table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.employee_audit_table_container table tbody tr:last-child {
    border: none;
}

.employee_audit_table_container table tbody tr:hover {
    background: #F4F4F4;
}

.employee_audit_table_container table tbody tr td {
    padding: 12px;
}