.build_profile {
  margin-top: 20px;
  margin-left: 20px;
}

.build_profile_header {
  margin-top: 20px;
}

.build_profile_header_name {
  font-size: 22px;
  color: #142952;
  font-weight: 500;
  margin-left: 10px;
}

.build_profile_container {
  width: 90%;
  padding: 20px 0;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.build_profile_span_1 {
  display: flex;
  padding: 10px 20px;
}

.build_profile_span_field {
  width: 50%;
  display: flex;
  align-items: center;
}

.build_profile_span_field_input {
  width: 50%;
}

.build_profile_span_2 {
  padding: 10px 20px;
  display: flex;
  align-items: flex-end;
}

.build_profile_span_2_field {
  width: 55%;
}

.build_profile_span_2_field textarea:focus,
.build_profile_span textarea:focus {
  outline: 1px solid #1976d2;
}

.build_profile_span_2_icon {
  margin: 0 0 20px 10px;
}

.build_profile_span {
  margin: 10px 20px;
  width: 100%;
}

.build_profile_domain_add_icon {
  color: #1c73ba !important;
  margin-left: 20px;
  cursor: pointer;
}

.build_profile_span_table {
  width: 50%;
  padding: 10px 20px;
}

.build_profile_span_table table {
  width: 70%;
  border-collapse: collapse;
  border-radius: 5px;
}

.build_profile_span_table table thead {
  background: #498fc7;
  color: #fff;
}

.build_profile_span_table table thead tr th {
  padding: 10px;
  text-align: center;
}

.build_profile_span_table table tbody tr {
  background: #fafafa;
  border-bottom: 1px solid gainsboro;
}

.build_profile_span_table table tbody tr td {
  text-align: center;
  padding: 10px;
}

.build_profile_span_table table tbody tr:hover {
  background: #f1f3fa;
}

.build_profile_span_table table tbody tr td:last-child {
  cursor: pointer;
}

.build_profile_btn {
  padding: 20px;
}

.build_profile_btn input {
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background: var(--bg-color);
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: 0.6s;
}

.build_profile_error_show {
  display: block;
  color: red;
  padding: 5px 10px;
}

.build_profile_error_hide {
  display: none;
}

.build_profile_length_show {
  display: block;
  color: red;
  padding: 0 10px;
}

.build_profile_length_hide {
  display: block;
  padding: 0 10px;
}

/* Comment ALert */

.build_profile_comment_alert_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
}

.build_profile_comment_alert {
  background: #fff;
  width: 300px;
  padding: 10px;
  box-shadow: 0 0 10px #000;
  border-radius: 5px;
}

.build_profile_comment_text_box p {
  float: right;
  font-size: 12px;
}

.build_profile_comment_text_box textarea {
  width: 100%;
  height: 100px;
  padding-left: 10px;
  outline: none;
  border-radius: 5px;
  border: 1px solid gainsboro;
}

.build_profile_comment_text_box textarea:focus {
  border: 1px solid #1866a7;
}

.build_profile_comment_text_box textarea:hover {
  border: 1px solid #1c73ba;
}

.build_profile_comment_btns {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.build_profile_comment_btns button {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background: #2c8cec;
  color: #fff;
  cursor: pointer;
}

.build_profile_comment_btns button:hover {
  transform: scale(1.1);
}

.build_profile_comment_btns button:first-child {
  margin-right: 10px;
}
