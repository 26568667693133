.edit_assign_project_code_container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_assign_project_code_container_card {
  width: 600px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px #00000014;
}

.edit_assign_project_code_container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #d9d5ec;
  border-radius: 5px 5px 0 0;
}

.edit_assign_project_code_container_header_title span {
  font-size: 22px;
  font-weight: 600;
  color: #142952;
}

.edit_assign_project_code_container_content {
  padding: 10px;
  max-height: 80vh;
  overflow: auto;
}

.edit_assign_project_code_container_content::-webkit-scrollbar {
  width: 0px;
}

.edit_assign_project_code_container_content_span {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
}

.edit_assign_project_code_container_content_span_1 {
  width: 100%;
  margin-top: 40px;
  margin-left: 10px;
  display: flex;
  gap: 15px;
}

.edit_assign_project_code_container_span_box {
  width: 47%;
  color: #252525;
}

.edit_assign_project_code_container_span_box label {
  font-size: 14px;
  font-weight: 500;
}

.edit_assign_project_code_container_span_box label::after {
  content: ":";
}

.edit_assign_project_code_container_span_box span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
}

.edit_assign_project_code_container_actions {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.edit_assign_project_code_container_actions button {
  width: 100px;
  height: 35px;
  border-radius: 4px;
  margin-right: 10px;
  font-weight: 550;
  cursor: pointer;
}

.edit_assign_project_code_container_actions button:first-child {
  background: #ffffff;
  border: 0.5px solid #252525;
}

.edit_assign_project_code_container_actions button:last-child {
  background: var(--bg-color);
  color: #ffffff;
  border: none;
}

.edit_assign_project_selected_items {
  padding: 5px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
}

.edit_assign_project_selected_items span {
  display: flex;
  gap: 5px;
  padding: 5px 8px;
  border-radius: 20px;
  background: #ebebeb;
  font-size: 12px;
  color: #646464;
}
