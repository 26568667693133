.weekly_timesheet {
    width: 95%;
    /* margin-top: 20px; */
    margin-left: 20px;
}

.weekly_timesheet_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px;
}

.weekly_timesheet_container_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.weekly_timesheet_container_navigate_date {
    height: 30px;
    width: 130px;
}

.weekly_timesheet_container_navigate_date label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
}

.weekly_timesheet_container_navigations {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.weekly_timesheet_container_navigations_dt {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 5px;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    width: 70px;
    height: 30px;
}

.weekly_timesheet_month_container {
    display: flex;
    align-items: center;
}

.weekly_timesheet_month_container span {
    font-weight: bold;
    margin-right: 5px;
}

.weekly_timesheet_month_container select {
    height: 30px;
    border: .5px solid #D7D7D7;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.weekly_timesheet_month_container select:focus,
.weekly_timesheet_month_container select:hover {
    border: .5px solid #D7D7D7;
}

.weekly_timesheet_content {
    height: 78vh;
    overflow-y: auto;
    background: #ffffff;
    width: 100%;
    /* padding: 10px; */
    /* border-radius: 8px; */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.weekly_timesheet_content::-webkit-scrollbar {
    width: 7px;
}

.weekly_timesheet_content::-webkit-scrollbar-thumb {
    background: #7a7a7a;
    border-radius: 8px;
}

.weekly_timesheet_content table {
    border-collapse: collapse;
    text-align: center;
    width: 100%;
}

.weekly_timesheet_content table thead tr {
    position: sticky;
    top: -6px;
    background: #ffffff;
    /* border-bottom: 1px solid #D9D5EC; */
    color: #142952;
}

.weekly_timesheet_content table thead tr td {
    padding: 10px;
    font-weight: 550;
    border-right: 1px solid #D9D5EC;
    border-left: 1px solid #D9D5EC;
    border-bottom: 1px solid #D9D5EC;
}

.weekly_timesheet_content table thead tr td:first-child,
.weekly_timesheet_content table tbody tr td:first-child {
    font-weight: 550;
    text-align: center;
}

.weekly_timesheet_content table tbody {
    color: #142952;
}

.weekly_timesheet_content table tbody tr {
    /* border-bottom: 1px solid #D9D5EC; */
    cursor: pointer;
}

.weekly_timesheet_content table tbody tr:last-child {
    border: none;
}

.weekly_timesheet_content table tbody tr:hover {
    /* background: #DDE6ED; */
    background: #F4F4F4;
}

.weekly_timesheet_content table tbody tr td {
    /* padding: 15px; */
    padding: 5px;
    border: 1px solid #D9D5EC;
}

.weekly_timesheet_content_active {
    /* background: #DDE6ED; */
    background: #F4F4F4;
}


.react-datepicker__input-container {
    display: none !important;
    width: 5px !important;
}

.react-datepicker__month-container {
    background: #fff;
}

#datepicker {
    display: none;
}

.react-datepicker-wrapper {
    width: 5px !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker {
    /* margin-left: -150px; */
    margin-top: -15px;
    width: 170px;
}



.weekly_status_pending {
    background: #e8f1ff;
    color: #0250ec;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.weekly_status_rejected {
    background: #ffe5e8;
    color: #d20319;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.weekly_status_approved {
    background: #d8f7e6;
    color: #00692a;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.weekly_status_inapproval {
    background: #ffefe4;
    color: #eeb495;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.weekly_status_na {
    background: #e6e6e6;
    color: #7a7a7a;
    padding: 2px 18px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}