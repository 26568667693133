.edit_user_data {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.edit_user_data_center {
    background: #fff;
    border-radius: 5px;
    margin-top: 10px;
    width: 450px;
    position: relative;
}

.edit_user_data_header {
    background-color: #F1F2FF;
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
}

.edit_user_data_header span {

    display: block;
}

.edit_user_data_text_fields {
    max-height: 75vh;
    overflow: auto;

}

.edit_user_data_text_fields::-webkit-scrollbar {
    width: 5px;
}

.edit_user_data_text_fields::-webkit-scrollbar-thumb {
    background: var(--bg-color);
    border-radius: 5px;
}

.edit_user_data_block {
    padding: 10px;
}

.edit_user_data_block label {
    font-size: 17px;
    font-weight: 600;
}

.edit_user_data_block input,
.edit_user_data_block select {
    width: 100%;
    height: 30px;
    border: 1px solid #6350FF;
    padding-left: 10px;
    outline: none;
    border-radius: 5px;
    box-shadow: 0 0 2px #000;
    display: flex;
    padding: 10px 20px;
}

.edit_user_span_1 {
    display: flex;
    padding: 20px 20px;
}

.edit_user_span_field {
    width: 100%;
    display: flex;
    align-items: center;

}

.edit_user_span_field_input {
    width: 100%;
    height: 35px;

}

.edit_user_data_block input:focus,
.edit_user_data_block select:focus {
    border: 10px solid #ffb050;
}

.edit_user_btn {
    padding: 10px;
}

.edit_user_btn button {
    width: 90px;
    height: 35px;
    border-radius: 5px;
    background: var(--bg-color);
    color: white;
    border: 1px solid #6350FF;
    cursor: pointer;
    transition: .5s;
    box-shadow: inset 0 0 10px #6350FF;
}


.edit_user_clsoe {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.show_error {
    font-size: 12px;
    color: red;
    padding-left: 5px;
}

.edit_user_navigations {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}