.timesheet_approval_main_screen {
    width: 100%;
    margin-left: 20px;
}

.timesheet_approval_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px 20px;
}

.timesheet_approval_container_header_title {
    display: flex;
    align-items: center;
    gap: 5px;
}

.timesheet_approval_container_header_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.timesheet_approval_container_header_title label {
    background: #142952;
    color: #fff;
    width: 25px;
    height: 25px;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timesheet_approval_header_navigations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%
}

.timesheet_approval_container_header_navigations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 16%;
}

.timesheet_approval_container_header_navigations button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
}


.timesheet_approval_container_header_navigations button {
    background: var(--bg-color);
    color: #ffffff;
    border: none;
}

.timesheet_approval_container_table {
    height: 66vh;
    overflow-y: auto;
    background: #ffffff;
    width: 99%;
    /* padding: 10px; */
    /* border-radius: 8px; */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.timesheet_approval_container_table::-webkit-scrollbar {
    width: 1px;
}

.timesheet_approval_container_table table {
    border-collapse: collapse;
    width: 100%;
    background: #fff;
}

.timesheet_approval_container_table table thead tr {
    position: sticky;
    /* top: -10px; */
    top: -2px;
    background: #ffffff;
    border-bottom: 1px solid #D9D5EC;
    color: #142952;
}

.timesheet_approval_container_table table thead tr td {
    padding: 5px;
    /* padding: 10px; */
    font-weight: 550;
    font-size: 14px;
    border: 1px solid #D9D5EC;
}

.timesheet_approval_container_table table thead tr td:first-child {
    font-weight: 550;
}

.timesheet_approval_container_table table tbody {
    color: #142952;
}

.timesheet_approval_container_table table tbody tr {
    /* border-bottom: 1px solid #D9D5EC; */
    cursor: pointer;
}

/* 
.timesheet_approval_container_table table tbody tr:last-child {
    border: none;
} */

.timesheet_approval_container_table table tbody tr:hover {
    /* background: #DDE6ED; */
    background: #F4F4F4;
}

.timesheet_approval_container_table table tbody tr td {
    /* padding: 15px; */
    padding: 5px;
    border: 1px solid #D9D5EC;
}




.weekly_status_inapproval {
    background: #ffefe4;
    color: #eeb495;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}


.weekly_status_rejected {
    background: #ffe5e8;
    color: #d20319;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.weekly_status_approved {
    background: #d8f7e6;
    color: #00692a;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.Approval_filters_container {
    position: relative;
    transition: .5s;
}

.Approval_filters_container_toggle {
    width: 30px;
    height: 30px;
    background: #858585;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: -30px;
}

.TimeSheet_Approval_filters {
    height: 80vh;
    overflow-y: auto;
    width: 100%;
    border-right: 1px solid #DDD8FF;
}

.TimeSheet_Approval_filters::-webkit-scrollbar {
    width: 2px;
}

.TimeSheet_Approval_filters_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.TimeSheet_Approval_filters_header_title {
    display: flex;
    align-items: center;
}

.TimeSheet_Approval_filters_header_title span {
    margin-left: 5px;
    color: #000000;
    font-size: 17px;
}

.TimeSheet_Approval_filters_header_clear {
    cursor: pointer;
    color: var(--bg-color);
}


.TimeSheet_Approval_filter_container {
    background: #ffffff;
    margin: 10px;
    border-radius: 8px;
    padding: 7px;
}

.TimeSheet_Approval_filter_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    color: #252525;
    font-weight: 500;
    /* border-bottom: 1px solid #C4C4C4; */
}

.TimeSheet_Approval_filter_container_content {
    margin-top: 20px;
    min-height: 100px;
    position: relative;
}

.timesheet_timeline_btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 70px;
    height: 30px;
    border-radius: 4px;
    border: none;
    background: var(--bg-color);
    color: #ffffff;
    cursor: pointer;
}

.TimeSheet_Approval_filter_container_content ul {
    padding: 10px;
    margin: 0;
    list-style: none;
}

.TimeSheet_Approval_filter_container_content ul li input {
    margin-top: 5px;
    margin-right: 10px;
}

.timesheet_timeline_btn_1 button,
.TimeSheet_Approval_filter_container_content_span_1 button {
    margin: 20px 10px;
    width: 70px;
    height: 35px;
    border-radius: 4px;
    border: none;
    background: var(--bg-color);
    color: #ffffff;
    cursor: pointer;
}

/* .timesheet_timeline_btn_1 {
    text-align: right;
} */

.TimeSheet_Approval_filter_container_content_span {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.TimeSheet_Approval_filter_container_content_span input {
    width: 30%;
    padding-left: 5px;
}


.timesheet_approval_filters_clear_all {
    transition: .4s;
    cursor: pointer;
}

.timesheet_approval_filters_clear_all:hover {
    scale: 1.1;
}

.employees_filter_container_content .react-datepicker__input-container {
    display: none;
}

.employees_filter_container_content .react-datepicker__month-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.employees_filter_container_content .react-datepicker__week:hover,
.employees_filter_container_content .react-datepicker__day:hover {
    background: #bad9f1 !important;
}

.employees_filter_container_content label {
    display: block;
}

.employees_filter_container_content_span label {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 0.5px solid #c8c8c8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}