.sub_menu_container {
    /* background: #F1F2FF; */
    background: #ffffff;
    padding: 10px 30px;
    display: flex;
    gap: 30px;
    border-bottom: .5px solid #DDD8FF;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 12;
}

.sub_menu_container a {
    font-size: 17px;
    color: #3E536E;
    text-decoration: none;
}

.sub_menu_container .active {
    font-weight: bold;
}