.outlook-container {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0;
}

.outlook-container-card {
    width: 1000px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
    border-radius: 5px;
}

.outlook-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.outlook-container-header span {
    font-size: 22px;
    font-weight: 600;
    color: #252525;
    display: flex;
    gap: 20px;
}

.outlook-container-header label {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background: #093a46;
    color: #fff;
    padding: 5px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outlook-container-content {
    height: 60vh;
    overflow: auto;
}

.outlook-container-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.outlook-container-content::-webkit-scrollbar-track {
    background: #acacac;
    border-radius: 5px;
}

.outlook-container-content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #9b9b9b;
}

.outlook-container-content table {
    width: 100%;
    border-collapse: collapse;
}

.outlook-container-content table thead tr {
    position: sticky;
    top: 0px;
    background: #ffffff;
}

.outlook-container-content table thead tr th {
    padding: 10px;
}

.outlook-container-content table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
    font-size: 13px;
}

.outlook-container-content table tbody tr:hover {
    background: #F4F4F4;
}

.outlook-container-content table tbody tr td {
    padding: 15px;
}

.outlook-container-actions {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 30px;
    padding: 10px;
}

.outlook-container-actions button {
    height: 40px;
    color: #fff;
    background: var(--bg-color);
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 0 10px;
}

.outlook-container-actions button:hover,
.outlook-container-actions button:focus {
    border: none;
    outline: none;
}