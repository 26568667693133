.employees_content {
    height: 55vh;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    width: 95%;
    margin: auto;
    margin-top: 20px;
    border-radius: 5px;
}

.employees_content::-webkit-scrollbar {
    width: 6px;
}

.employees_content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.employees_content::-webkit-scrollbar-thumb {
    background: #c7c7c7;
}

.employees_content table {
    border-collapse: collapse;
    width: 100%;
}

.employees_content table thead tr {
    position: sticky;
    top: -10px;
    background: #ffffff;
}

.employees_content table thead tr th {
    padding: 10px;
}

.employees_content table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.employees_content table tbody tr:hover {
    background: #F4F4F4;
}

.employees_content table tbody tr td {
    padding: 15px;
}

.employee-weekly--container {
    width: 95%;
    margin: 20px auto;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 20px;
}