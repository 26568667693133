.employee_profile_container {
    width: 95%;
    margin-top: 20px;
    margin-left: 20px;
}

.employee_profile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px;
}

.employee_profile_header_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.employee_profile_header_navigations {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employee_profile_mode {
    border: 1px solid #6350FF;
    background: #ffffff;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}


.employee_profile_mode_landscape {
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #00000014;
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
}

.employee_profile_mode_landscape_image_container {
    width: 200px;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.dummy_profile_image {
    width: 170px;
    height: 170px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dummy_profile_image span {
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 600;
}

.employee_profile_image_container {
    width: 170px;
    height: 170px;
}

.employee_profile_image_container img {
    width: 100%;
    border-radius: 5px;
    object-fit: contain;
}

.employee_profile_mode_landscape_content {
    width: 75%;
}

.employee_profile_mode_landscape_content_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0;
}

.employee_profile_l_details_span_1 span {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 550;
    color: #252525;

}

.employee_profile_l_details_span_1 label {
    font-size: 14px;
    color: #252525;
}

.employee_profile_l_details_span_2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-transform: capitalize;
}

.employee_profile_l_details_span_2 span {
    font-size: 14px;
}

.employee_profile_l_details_span_2 img {
    margin-right: 5px;
}

.employee_profile_mode_landscape_content_span_2 {
    display: flex;
    width: 100%;
    padding: 10px 0;
}


.employee_profile_mode_l_content_span_2_container,
.employee_profile_mode_l_content_span_3_container {
    width: 35%;
}

.employee_profile_mode_l_content_span_2_container label,
.employee_profile_mode_l_content_span_3_container label {
    font-size: 14px;
    color: #6C6C6C;
    font-size: 700;
    display: block;
}

.employee_profile_mode_l_content_span_2_container span,
.employee_profile_mode_l_content_span_3_container span {
    font-size: 15px;
    color: #252525;
    text-transform: capitalize;
    font-weight: 550;
}

.employee_profile_mode_landscape_content_span_3 {
    display: flex;
    padding: 10px 0;
}


.employee_profile_mode_l_content_span_3_container ul li {
    text-transform: capitalize;
}

.employee_profile_mode_landscape_content_span_4 {
    padding: 10px 0;
}


.employee_profile_mode_portrait_container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.employee_profile_mode_portrait {
    width: 48%;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #00000014;
    border-radius: 5px;
}


.employee_profile_mode_portrait_span_1 {
    display: flex;
    padding: 15px 10px;
}

.employee_profile_mode_portrait_detials {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 15px;
}


.employee_profile_mode_portrait_detials_span_1 span {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 550;
    color: #252525;
}

.employee_profile_mode_portrait_detials_span_1 label {
    text-transform: capitalize;
    font-size: 14px;
    color: #252525;
}

.employee_profile_mode_portrait_detials_span_2 span {
    color: #252525;
    text-transform: capitalize;
    font-size: 12px;
}

.employee_profile_mode_portrait_detials_span_2 img {
    margin-right: 5px;
    width: 15;
}

.employee_profile_mode_portrait_span_2,
.employee_profile_mode_portrait_span_3 {
    display: flex;
}

.employee_profile_mode_portrait_span_container {
    width: 35%;
    padding: 10px;
}

.employee_profile_mode_portrait_span_container label,
.employee_profile_mode_portrait_span_4 label,
.employee_profile_mode_portrait_span_5 label {
    display: block;
    font-size: 14px;
    color: #6C6C6C;
    font-size: 700;
}

.employee_profile_mode_portrait_span_container span {
    font-size: 15px;
    color: #252525;
    text-transform: capitalize;
    font-weight: 550;
}

.employee_profile_mode_portrait_span_4,
.employee_profile_mode_portrait_span_5 {
    padding: 10px;
}