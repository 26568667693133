.timesheet_reports_main_screen {
    width: 100%;
    margin-left: 10px;
    height: 89vh;
}

.timesheet_reports_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px;
}

.timesheet_reports_container_header_title {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 20px;
}

.timesheet_reports_container_header_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.timesheet_reports_container_header_title label {
    background: #142952;
    color: #fff;
    width: 25px;
    height: 25px;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timesheet_reports_container_header_export_btn {
    display: flex;
    gap: 20px;
}

.timesheet_reports_container_header_export,
.timesheet_reports_container_header_export_span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.timesheet_reports_container_header_export button,
.timesheet_reports_container_header_export_span button {
    padding: 0 15px;
    background: var(--bg-color);
    color: #ffffff;
    border: none;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
}

.timesheet_reports_header_navigations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%
}

.timesheet_reports_container_header_navigations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 16%;
}

.timesheet_reports_container_header_navigations button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
}


.timesheet_reports_container_header_navigations button {
    background: var(--bg-color);
    color: #ffffff;
    border: none;
}

.timesheet_reports_container_table {
    height: 72vh;
    overflow-y: auto;
    background: #ffffff;
    width: 99%;
    /* padding: 10px; */
    /* border-radius: 8px; */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.timesheet_reports_container_table::-webkit-scrollbar {
    width: 1px;
}

.timesheet_reports_container_table table {
    border-collapse: collapse;
    width: 100%;
}

.timesheet_reports_container_table table thead tr {
    position: sticky;
    top: -3px;
    background: #ffffff;
    border-bottom: 1px solid #D9D5EC;
    color: #142952;
}

.timesheet_reports_container_table table thead tr th {
    padding: 5px;
    /* padding: 10px; */
    font-weight: 550;
    font-size: 14px;
    border: 1px solid #D9D5EC;
}

.timesheet_reports_container_table table thead tr th:first-child {
    font-weight: 550;
    text-align: center;
}

.timesheet_reports_container_table table thead tr th span {
    display: flex;
    gap: 5px;
}

.timesheet_reports_container_table table tbody {
    color: #142952;
}

.timesheet_reports_container_table table tbody tr td:first-child {
    text-transform: capitalize;
}

.timesheet_reports_container_table table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.timesheet_reports_container_table table tbody tr:hover {
    /* background: #DDE6ED; */
    background: #F4F4F4;
}

.timesheet_reports_container_table table tbody tr td {
    /* padding: 15px; */
    padding: 5px;
    border: 1px solid #D9D5EC;
}



.weekly_status_inapproval {
    background: #ffefe4;
    color: #eeb495;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}


.weekly_status_rejected {
    background: #ffe5e8;
    color: #d20319;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.weekly_status_approved {
    background: #d8f7e6;
    color: #00692a;
    padding: 2px 6px;
    border-radius: 8px;
    font-weight: 550;
    font-size: 12px;
}

.reports_filters_container {
    position: relative;
    transition: .5s;
}

.reports_filters_container_toggle {
    width: 30px;
    height: 30px;
    background: #858585;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: -30px;
}


.timesheet_reports_filters {
    width: 100%;
    border-right: 1px solid #DDD8FF;
    overflow-y: auto;
    height: 89vh;
}

.timesheet_reports_filters::-webkit-scrollbar {
    width: 2px;
}

.timesheet_reports_filters_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.timesheet_reports_filters_header_title {
    display: flex;
    align-items: center;
}

.timesheet_reports_filters_header_title span {
    margin-left: 5px;
    color: #000000;
    font-size: 17px;
}

.timesheet_reports_filters_header_clear {
    cursor: pointer;
    color: var(--bg-color);
}


.timesheet_reports_filter_container {
    background: #ffffff;
    margin: 10px;
    border-radius: 8px;
    padding: 7px;
}

.timesheet_reports_filter_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    color: #252525;
    font-weight: 500;
    /* border-bottom: 1px solid #C4C4C4; */
}


.timesheet_reports_filter_container_content {
    margin-top: 20px;
    min-height: 100px;
    position: relative;
}

.timesheet_timeline_btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 70px;
    height: 30px;
    border-radius: 4px;
    border: none;
    background: var(--bg-color);
    color: #ffffff;
    cursor: pointer;
}

.timesheet_reports_filter_container_content ul {
    padding: 10px;
    margin: 0;
    list-style: none;
}

.timesheet_reports_filter_container_content ul li input {
    margin-top: 5px;
    margin-right: 10px;
}

.timesheet_reports_filter_container_content_span_1 button,
.timesheet-reports-export-actions button {
    margin: 20px 10px;
    height: 35px;
    padding: 0 30px;
    border-radius: 4px;
    border: none;
    background: var(--bg-color);
    color: #ffffff;
    cursor: pointer;
}

.timesheet_timeline_btn_1 {
    text-align: right;
}

.timesheet_timeline_btn_1 button {
    margin: 20px 10px;
    width: 70px;
    height: 35px;
    border-radius: 4px;
    border: none;
    background: var(--bg-color);
    color: #ffffff;
    cursor: pointer;
}

.timesheet_reports_filter_container_content_span {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.timesheet_reports_filter_container_content_span input {
    width: 30%;
    padding-left: 5px;
}


.timesheet_reporting_filters_clear_all {
    transition: .4s;
    cursor: pointer;
}

.timesheet_reporting_filters_clear_all:hover {
    scale: 1.1;
}


.timesheet_reports_customization_container {
    background: #ffffff;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}

.timesheet_reports_customization_container_heading {
    border-bottom: 1px solid #252525;
}

.timesheet_reports_customization_container_heading span {
    font-size: 16px;
    color: #252525;
    font-weight: 400;
}

.timesheet_reports_customization_container_list {
    margin-top: 10px;
}

.timesheet-reports-export-content-element {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    padding-top: 2px;
    gap: 10px;
    cursor: pointer;
}

.timesheet-reports-export-content-element input {
    cursor: pointer;
}

/* 
.employees_filter_container_content_span .react-datepicker__input-container {
    display: none;
}

.employees_filter_container_content_span .react-datepicker__month-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.employees_filter_container_content_span .react-datepicker__week:hover,
.employees_filter_container_content_span .react-datepicker__day:hover {
    background: #bad9f1 !important;
} */

.employees_filter_container_content_span label {
    display: block;
}

.employees_filter_container_content_span_span label {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 0.5px solid #c8c8c8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



.timesheet_reports_container_header_export_span {
    position: relative;
    display: inline-block;
}

.dropdown-content_export {
    display: none;
    position: absolute;
    top: 30px;
    left: -5px;
    background-color: #f1f1f1;
    width: 80px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content_export ul {
    margin: 0;
    padding: 0;
}

.dropdown-content_export li {
    color: black;
    padding: 10px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown-content_export ul li:hover {
    background-color: #ddd;
}

.timesheet_reports_container_header_export_span:hover .dropdown-content_export {
    display: block;
}

.timesheet_reports_container_header_export_span:hover .dropbtn_export {
    padding: 0 15px;
    background: var(--bg-color);
    color: #ffffff;
    border: none;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
}

.timesheet_reports_filter_container_content_span .react-datepicker {
    font-family: Arial, sans-serif;
    /* border: 1px solid gainsboro; */
    border-radius: 4px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    width: 100%;
    max-width: 300px;
    /* Set a maximum width for larger screens if needed */
    margin: 0 auto;
}

.timesheet_reports_filter_container_content_span .react-datepicker__header {
    background-color: #ffffff;
    border-bottom: 1px solid #ccc;
}

.timesheet_reports_filter_container_content_span .react-datepicker__navigation {
    /* color: #333; */
    color: rgb(7, 7, 7);
}

.timesheet_reports_filter_container_content_span .react-datepicker__current-month {
    color: #333;
    font-size: small;
}

.timesheet_reports_filter_container_content_span .react-datepicker__day,
.timesheet_reports_filter_container_content_span .react-datepicker__day-name {
    /* color: #333; */
    color: #252525;
}

.timesheet_reports_filter_container_content_span .react-datepicker__day--selected,
.timesheet_reports_filter_container_content_span .react-datepicker__day--in-selecting-range,
.timesheet_reports_filter_container_content_span .react-datepicker__day--in-range {
    background-color: #007bff;
    color: #fff;
}

.timesheet_reports_filter_container_content_span .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
}

.timesheet_reports_filter_container_content_span .react-datepicker__day--disabled {
    color: #ccc;
    cursor: not-allowed;
}

.timesheet_reports_filter_container_content_span .react-datepicker__week-number {
    /* color: #333; */
    color: #6350ff;
}

.timesheet_reports_filter_container_content_span .react-datepicker__day-names {
    display: flex;
    justify-content: space-evenly;
    margin-top: 4px;
    margin-bottom: 1px;
}

.timesheet_reports_filter_container_content_span .react-datepicker__input-container {
    display: block;
    margin-bottom: 8px;
    z-index: 2;
}

.timesheet_reports_filter_container_content_span .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Responsive styles */
@media (min-width: 768px) {
    .timesheet_reports_filter_container_content_span .react-datepicker {
        max-width: 400px;
    }
}