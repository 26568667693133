.normal_alert {
    position: fixed;
    top: 10px;
    left: 35%;
    width: 320px;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
    user-select: none;
}

.normal_alert span {
    margin-bottom: 5px;
    margin-left: 10px;
}