.temp_changes_header {
    border-radius: 5px;
    background: #498fc7;
    color: #fff;
    box-shadow: inset 0 0 10px #10456f;
    display: flex;
    align-items: center;
    height: 50px;
    width: 90%;
}

.temp_changes_header_goback_btn {
    width: 70px;
    height: 100%;
    border-radius: 5px 0 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #2b75b1;
}

.temp_changes_header_goback_btn:hover {
    background: #10456f;
}

.temp_changes_header_name {
    font-size: 18px;
    margin-left: 20px;
}

.temp_changes_span {
    width: 90%;
    margin-top: 20px;
}

.temp_changes_span_comments {
    width: 50%;
    box-shadow: 0 0 10px #00000029;
    border-radius: 5px;
    padding: 10px;
}

.temp_changes_span_comments span {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}

.btn_temp_changes_approve,
.btn_temp_changes_rejected {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #00000029;
    cursor: pointer;
}

.btn_temp_changes_approve {
    background: green;
    color: #fff;
}

.btn_temp_changes_approve:hover,
.btn_temp_changes_rejected:hover {
    transform: scale(1.1);
}

.btn_temp_changes_rejected {
    background: red;
    color: #fff;
    margin-left: 20px;
}

.temp_changes_span_profile {
    box-shadow: 0 0 10px #00000029;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.temp_changes_profile_container {
    box-shadow: 0 0 10px #00000029;
    border-radius: 5px;
    width: 49%;
    padding: 10px;
}

.temp_changes_profile_container span {
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}

.temp_changes_profile_span_1 {
    padding: 10px;
}

.temp_changes_profile_card {
    box-shadow: 0 0 10px #00000029;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}

.temp_changes_profile_card span {
    font-size: 18px;
}

.temp_changes_profile_card p {
    font-size: 17px;
    padding-left: 10px;
    text-transform: capitalize;
}


/*  */



.employee-review-container {
    width: 95%;
    margin-top: 20px;
    margin-left: 20px;
}

.employee-review-header {
    user-select: none;
    padding: 10px;
}

.employee-review-header span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.employee-review-actions {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 10px;
}

.employee-review-actions-comment-header span {
    font-size: 20px;
    font-weight: 550;
    color: #142952;
}

.employee-review-actions-comment-content {
    padding: 5px 10px;
}

.employee-review-actions-btns {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.employee-review-actions-btns button {
    width: 120px;
    height: 30px;
    border: none;
    color: #ffffff;
    background: var(--bg-color);
    border-radius: 4px;
}

.employee-review-content {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    margin-top: 20px;
    margin-bottom: 30px;
}


.employee-review-content::-webkit-scrollbar {
    width: 1px;
}


.employee-review-content table {
    border-collapse: collapse;
    width: 100%;
}

.employee-review-content table thead tr {
    position: sticky;
    top: -5px;
    background: #ffffff;
    border-bottom: 1px solid #D9D5EC;
    color: #142952;
}

.employee-review-content table thead tr td {
    padding: 10px;
    font-weight: 550;
}

.employee-review-content table tbody {
    color: #142952;
}

.employee-review-content table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.employee-review-content table tbody tr:hover {
    background: #F4F4F4;
}

.employee-review-content table tbody tr td {
    padding: 15px;
}