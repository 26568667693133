.registered_main_page {
    background: #000;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registered_card {
    width: 40%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px #fff;
    padding: 20px;
}

.checked_image_container {
    text-align: center;
}

.registered_info h3 {
    text-align: center;
}

.registered_info button {
    width: 100px;
    height: 35px;
    border: none;
    background: #1c73ba;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    transition: .8s;
}

.registered_info button:hover {
    transform: scale(1.1);
    transition: .8s;
}

.registered_info {
    text-align: center;
}


.registered_info label {
    font-weight: 700;
    font-size: 18px;
    margin-top: 30px;
    display: block;
    text-align: left;
}

.registered_info ul {
    text-align: left;
}