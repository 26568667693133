.users_approval {
    width: 95%;
    margin-top: 20px;
    margin-left: 20px;
}

.user_approval_header {
    user-select: none;
    padding: 10px;
}

.user_approval_header span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}


.user_approval_content {
    height: 70vh;
    overflow-y: auto;
    background: #ffffff;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.user_approval_content::-webkit-scrollbar {
    width: 1px;
}

.user_approval_content table {
    border-collapse: collapse;
    width: 100%;
}

.user_approval_content table thead tr {
    position: sticky;
    top: -10px;
    background: #ffffff;
    border-bottom: 1px solid #D9D5EC;
    color: #142952;
}

.user_approval_content table thead tr td {
    padding: 10px;
    font-weight: 550;
}

.user_approval_content table thead tr td:last-child {
    text-align: center;
}

.user_approval_content table tbody {
    color: #142952;
}

.user_approval_content table tbody tr {
    border-bottom: 1px solid #D9D5EC;
    cursor: pointer;
}

.user_approval_content table tbody tr:last-child {
    border: none;
}

.user_approval_content table tbody tr:hover {
    background: #F4F4F4;
}

.user_approval_content table tbody tr td {
    padding: 15px;
}

.user_approval_content table tbody tr td:last-child {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.user_approval_content table tbody tr td img {
    width: 25px;
}