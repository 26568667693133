.approvals-container {
    background-image: url('../../assets/genzeon-background-img.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.approvals-card {
    width: 400px;
    height: 300px;
    background: #ffffff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.approvals-card p {
    font-size: 28px;
    color: #252525;
    text-align: center;
    font-weight: 600;
}