.send_mail_header {
    border-radius: 5px;
    background: #498fc7;
    color: #fff;
    box-shadow: inset 0 0 10px #10456f;
    display: flex;
    align-items: center;
    height: 50px;
    width: 90%;
}

.send_mail_header_goback_btn {
    width: 70px;
    height: 100%;
    border-radius: 5px 0 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #2b75b1;
}

.send_mail_header_goback_btn:hover {
    background: #10456f;
}

.send_mail_header_name {
    font-size: 18px;
    margin-left: 20px;
}

.send_mail_dialog {
    margin-top: 30px;
    border-radius: 5px;
    box-shadow: 0 0 3px #000;
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

/* Login Dialog */
.sendmail_container {
    width: 95%;
    margin-top: 20px;
    margin-left: 20px;
}

.sendmail_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    padding: 10px;
}

.send_mail_login_link {
    width: 450px;
    height: 414px;
    top: 178px;
    left: 156px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px 0px #00000014;
    padding: 10px;
    background: #ffffff;
}

.send_mail_login_link_two {
    width: 650px;
    height: 414px;
    top: 178px;
    left: 687px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px 0px #00000014;
    padding: 10px;
    background: #ffffff;

}

.send_mail_login_input_box_registration {
    width: 654px;
    height: 136px;
    top: 262px;
    left: 707px;
    border-radius: 4px;
    border: 0.5px;

}

.registration_link_textarea {
    width: Fixed (654px);
    height: Fixed (110px);
    top: 288px;
    left: 707px;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    border: 0.5px;
    gap: 10px
}

.send_mail_fiex_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.send_mail_login_header {
    width: 377px;
    height: 246px;
    top: 208px;
    left: 176px;
    border-radius: 4px
}

.send_mail_login_header_two {
    width: 654px;
    height: 265px;
    top: 208px;
    left: 707px;
    border-radius: 4px
}

.send_mail_login_input_box {
    margin-top: 20px;
    width: 301px;
    height: 65px;
    top: 262px;
    left: 176px;
    border-radius: 4px;
    border: 0.5px;

}

.send_mail_login_input_box label {
    font-weight: 600;
}

.send_mail_login_input_box textarea,
.send_mail_login_input_box input {
    width: 95%;
    height: 30px;
    border: 1px solid gainsboro;
    padding-left: 10px;
    outline: none;
    border-radius: 5px;
    box-shadow: 0 0 2px #000;
}

.send_mail_login_input_box input:focus,
.send_mail_login_input_box textarea:focus {
    border: 1px solid #1c73ba;
}

.send_mail_login_instructions {
    margin-top: 30px;
}

.send_mail_login_instructions ul {
    font-size: 14px;
}

.sendmail_textarea {
    padding-top: 10px;
    width: 377px;
    height: 15px;
    left: 176px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.send_mail_login_btn {
    text-align: right;
}

.send_mail_login_btn input {
    width: 90px;
    height: 35px;
    border-radius: 4px;
    background: var(--bg-color);
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.send_mail_login_btn_clear input {
    width: 90px;
    height: 35px;
    border-radius: 4px;
    background: #ffffff;
    color: black;
    border: .5px solid #25213B;
    cursor: pointer;
}

/* Register Dialog */

.send_mail_register_link {
    box-shadow: 0 0 3px #000;
    border-radius: 5px;
    width: 45%;
    margin: 20px;
    padding: 10px;
}

.send_mail_login_input_box textarea {
    padding-top: 10px;
    height: 100px;
}

.login_link_abc {
    width: 301px;
    height: 65px;
    top: 262px;
    left: 176px;
    border-radius: 4px;
    border: 0.5px
}

.login_link_MailAdress {
    width: Fixed (301px);
    height: Fixed (40px);
    top: 287px;
    left: 176px;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    border: 0.5px;
    gap: 10px;

}


.sendmail_container_navigations {
    padding-top: 60px;
    display: flex;
    justify-content: flex-end;
}

.sendmail_container_navigation_btn {
    width: 33%;
    display: flex;
    justify-content: space-between;
}