/* @font-face {


  font-family: Montserrat-Medium;
  src: url('./Montserrat/Montserrat-Regular.ttf') ;


  font-family: swansea;
  src: url('./font/Swansea-q3pd.ttf')
} */

:root {
  --bg-color: #6350FF;
}

body {
  margin: 0;
  /* font-family: Montserrat-Medium; */
  /* font-family: swansea; */

  font-family: "open-sans", sans-serif;
  font-weight: 400;
  font-style: normal;

  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}