.assign_project_code_container {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.assign_project_code_container_card {
    width: 1000px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px #00000014;
}

.assign_project_code_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #D9D5EC;
    border-radius: 5px 5px 0 0;
}

.assign_project_code_container_header_title span {
    font-size: 22px;
    font-weight: 600;
    color: #142952;
}

.assign_project_code_container_content {
    padding: 10px;
    max-height: 80vh;
    overflow: auto;
    width: 68%;
    border-left: 1px solid #757575;
}

.assign_project_code_container_content::-webkit-scrollbar {
    width: 5px;
    height: 0;
}

.assign_project_code_container_content::-webkit-scrollbar-track {
    background: #dbdbdb;
}

.assign_project_code_container_content::-webkit-scrollbar-thumb {
    background: #bababa;
    border-radius: 5px;
}

.assign_project_code_container_content_span {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
}

.assign_project_code_container_content_span_1 {
    width: 100%;
    margin-top: 20px;
    margin-left: 10px;
}

.assign_project_code_container_span_box {
    width: 47%;
}

.assign_project_code_container_actions {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-top: 1px solid #757575;
}

.assign_project_code_container_actions button {
    width: 100px;
    height: 35px;
    border-radius: 4px;
    margin-right: 10px;
    font-weight: 550;
    cursor: pointer;
}

.assign_project_code_container_actions button:first-child {
    background: #ffffff;
    border: .5px solid #252525;
}

.assign_project_code_container_actions button:last-child {
    background: var(--bg-color);
    color: #ffffff;
    border: none;
}


.assign_project_selected_items {
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
}

.assign_project_selected_items span {
    display: flex;
    gap: 5px;
    padding: 5px 8px;
    border-radius: 20px;
    background: #ebebeb;
    font-size: 12px;
    color: #646464;
}

.allocations_container {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.allocations_container_filters {
    padding: 10px;
    width: 30%;
}

.allocations_container_filters_box {
    width: 100%;
    margin-top: 20px;
}

.allocations_container_filters_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.allocations_container_filters_header span {
    font-size: 18px;
    font-weight: 600;
    color: #252525;
    margin-left: 10px;
}

.allocations_container_filters_header button {
    border: none;
    outline: none;
    background: transparent;
    color: var(--bg-color);
    cursor: pointer;
    transition: .6s;
}

.allocations_container_filters_header button:hover {
    scale: 1.1;
}





.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-177ic5c {
    overflow-x: auto;

}
